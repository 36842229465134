<div [style.visibility]="showPage ? 'visible' : 'hidden'">
    <o-navbar
        [navItems]="navbarMenu"
        [hideInRoutes]="hideInRoutes"></o-navbar>
    <router-outlet></router-outlet>
    <o-footer>
        <ng-container header>
            <div class="d-flex flex-column align-items-center mb-4 mb-lg-0">
                <a-icon
                    source="/assets/icons/logo-limitless.svg"></a-icon>
            </div>
        </ng-container>
        <ng-container content>
            <p class="text-white text-center mb-0">Limitless – All rights reserved {{ date }}</p>
        </ng-container>
    </o-footer>
</div>
