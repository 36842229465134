<div class="home-page">
    <div class="header-section">
        <o-bg-image-section
            bgImage="/assets/img/hero-bg.webp"
            [overlay]="true"
            [parallaxValue]="0"
            [parallaxSpeed]="0.2"></o-bg-image-section>
    </div>
    <div class="space-bg position-relative">
        <div class="gradient-radial-1"></div>
        <div class="gradient-radial-2"></div>
        <div
            data-aos="fade-up"
            data-aos-delay="0"
            data-aos-once="true">
            <section>
                <div class="container d-flex flex-column align-items-center">
                    <h1 class="text-center fw-bold">
                        Welcome to
                        <br />
                        <span class="gradient-text">Limitless Animation</span>
                        Studios
                    </h1>
                    <p class="h4 text-center mb-5">
                        We invite you to step into a world where imagination knows no bounds.
                    </p>
                    <p class="h5 text-center">
                        Our studio is dedicated to breaking barriers and pushing the limits of what's possible with our
                        innovative approach to storytelling. We partner with talented creators to produce high-quality
                        IP that captivates and inspires audiences worldwide.
                    </p>

                    <a
                        class="text-center btn btn-primary"
                        href="mailto:hello@limitlesslabz.io">
                        Get In Touch
                    </a>
                </div>
            </section>
        </div>

        <section>
            <div class="container d-flex flex-column align-items-center">
                <div
                    data-aos="fade-up"
                    data-aos-delay="0"
                    data-aos-once="true">
                    <p class="text-center fw-bold h3 mb-6">
                        With
                        <span class="gradient-text">decades of experience</span>
                        working with top animation companies, we combine legendary creative storytelling with
                        <span class="gradient-text">cutting-edge technology</span>
                        and international production to create new worlds filled with engaging characters and timeless
                        stories.
                    </p>
                </div>
                <div
                    data-aos="fade-up"
                    data-aos-delay="0"
                    data-aos-once="true">
                    <div class="row gy-5 align-items-center">
                        <div class="col-md-6 col-lg-3 text-center">
                            <img
                                class="img-fluid"
                                src="/assets/img/logos/disney.png" />
                        </div>
                        <div class="col-md-6 col-lg-3 text-center">
                            <img
                                class="img-fluid paramount"
                                src="/assets/img/logos/paramount.png" />
                        </div>
                        <div class="col-md-6 col-lg-3 text-center">
                            <img
                                class="img-fluid"
                                src="/assets/img/logos/granity.jpg" />
                        </div>
                        <div class="col-md-6 col-lg-3 text-center">
                            <img
                                class="img-fluid"
                                src="/assets/img/logos/miramax.png" />
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <div
            data-aos="fade-up"
            data-aos-delay="0"
            data-aos-once="true">
            <section class="pb-0">
                <div class="container">
                    <div class="col-lg-10">
                        <p class="h2 fw-bold">Our passion for animation shines through in every project</p>
                        <p class="h4 mb-7">
                            We strive to build a community of fans who share our love for Animation and the limitless
                            possibilities it offers.
                        </p>
                    </div>
                </div>
                <div class="container-fluid px-lg-6">
                    <div class="journey-card p-4 p-lg-6">
                        <p class="h2 fw-bold">Join us on the journey!</p>
                        <p>Explore the unknown and unlock the door to imaginations beyond your wildest dreams.</p>
                        <p>Let’s create an incredible future, together. </p>

                        <a
                            class="text-center btn btn-secondary"
                            href="mailto:hello@limitlesslabz.io">
                            Get In Touch
                        </a>
                    </div>
                </div>
            </section>
        </div>

        <section class="pb-0"><o-partner-cards [partners]="partners"></o-partner-cards></section>
    </div>
</div>
