<div class="cards-section">
    <div class="container-xxl py-7">
        <div
            class="row card-row-item"
            *ngFor="let item of partners"
            data-aos="fade-up"
            data-aos-delay="200"
            data-aos-once="true">
            <div class="col-12">
                <div class="card-item">
                    <div
                        *ngIf="item.imgTitle"
                        class="my-4">
                        <a
                            target="_blank"
                            [href]="item.link">
                            <a-image
                                class="card-image-title"
                                alt="virtue animation"
                                [imageConfig]="item.imgTitle"
                                data-aos="fade-up"
                                data-aos-delay="200"
                                data-aos-once="true"></a-image>
                        </a>
                    </div>
                    <h4
                        *ngIf="item.title"
                        class="card-title ps-0 gradient-text-card fw-bold mb-4"
                        data-aos="fade-up"
                        data-aos-delay="200"
                        data-aos-once="true"
                        [innerHtml]="item.title"></h4>
                        <p
                            data-aos="fade-up"
                            data-aos-delay="200"
                            data-aos-once="true"
                            [innerHtml]="item.content"></p>

                </div>
            </div>
        </div>

    </div>
</div>
