<footer
    class="o-footer position-relative"
    [ngClass]="[containerClass ? containerClass : '']">
    <div class="copyright">
        <div class="container content py-5 d-flex justify-content-between">
            <ng-content select="[header]"></ng-content>
            <ng-content select="[content]"></ng-content>
        </div>
    </div>
</footer>
