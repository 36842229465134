import { CardModel } from './../../@shared/atomic/molecules/m-cards-item/m-cards-item.model';
import { Component, OnInit } from '@angular/core';
import { TeamMember } from '@app/@shared/cms/models/team-member.model';
import { PageBase } from '../../@shared/base/page.base';
import { MediaService } from '../../@shared/utils/media/media.service';
import { MediaPlayerModel } from '../../@shared/utils/media/media-player.model';
import { VideoOptions } from '../../@shared/atomic/organisms/o-video-section/o-video.model';
import { MessageServiceModel } from '../../@shared/utils/message/message.model';
import { AnimationsService } from '@src/app/@shared/utils/common/animations.service';
import { PartnerCardModel } from '../../components/atomic/organisms/o-partner-cards/o-partner-cards.model';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
})
export class HomeComponent extends PageBase implements OnInit {
    quote: string | undefined;
    isLoading = false;
    isPlaying = true;
    headerVideoBlock!: VideoOptions;
    joinSection!: any[];
    media!: MediaPlayerModel;
    lidershipMembers!: TeamMember[];
    cardsGlobalTech!: any[];
    innovativeProducts!: any[];
    cardsWithLinkCarousel: CardModel[] = [];
    content!: string;
    contentDefault: string = `We're a digital product agency <span class='brand-color'>advancing technology</span> for <br>`;
    partners: Array<PartnerCardModel> = [];

    phrasesToDecode = [
        "the world's leading companies.",
        'machine learning & augmented reality.',
        'surgical robots & AI-based systems.',
        'marketing & advertising organizations.',
        'healthcare & biotech.',
        'e-commerce & bidding platforms.',
        'smart devices & connected vehicles.',
        'think tanks, nonprofits, and NGOs.',
        'the media & entertainment industry.',
        'researchers and scientists.',
        'real-time collaboration platforms.',
    ];

    constructor(public mediaService: MediaService, public animationsService: AnimationsService) {
        super();
    }

    ngOnInit() {
        super.ngOnInit();

        this.partners = [
            {
                title: 'BOB BACON',
                content: `<h5>Co-Founder</h5><p>Bob is a distinguished animation executive with over 30 years of experience in the industry. Throughout his career, he has led some of the world's foremost animation studios and has been instrumental in producing 20 award-winning films, which together have grossed over $6 billion in global box office.</p><p>Bob began his career at Disney Animation Studios, where he served as EVP of Production and helped produce timeless classics such as Beauty and the Beast, Aladdin, The Lion King, Mulan, Tarzan, and Lilo and Stitch. </p><p>Bob was recently CEO of Virtue Animation Studio, and prior to co-founding the innovative Web3 pioneer, served as EVP of Animation for Granity Studios, the Academy Award-winning media company founded by the late NBA legend, Kobe Bryant.</p><p>He also held the position of Executive Vice President at Paramount Pictures and Head of Animation Production, where he launched the studio and oversaw production on the #1 box office smash, The SpongeBob Movie: Sponge Out of Water. Bob later became the CEO of Alpha Animation, one of China's leading entertainment and toy companies.</p><p>Bob's extensive experience also includes serving as the Production Executive for Miramax Films on the surprise hit, Gnomeo and Juliet, produced with the legendary Sir Elton John. The film grossed nearly $200 million for Disney's Touchstone Pictures.</p><p>Throughout his illustrious career, Bob has left an indelible mark on the animation industry, and his expertise and leadership continue to inspire and shape the future of animation.</p>`,

            },
            {
                title: 'Wags',
                content: `<h5>Co-Founder</h5><p>Wags is a seasoned strategist with a career spanning consumer brands, technology, music, and entertainment. Throughout his career, he has been a driving force behind multiple innovative and successful projects spanning these industries.</p><p>Wags has worked with Lyrical Lemonade, sports athletes, music, entertainment, and gaming. As a strategist, innovator, growth hacker, and community builder, Wags has a unique ability to identify trends and opportunities. He has helped brands in both traditional and digital spaces to reach new heights, leveraging his expertise in marketing, branding, and growth hacking to deliver outstanding results.</p><p>Early in his career, Wags quickly rose up the corporate ladder to become a young executive at a Fortune 100 company, leading strategy, brand, and marketing teams. </p><p>Wags macro goal is to help fuel disruption in the music, entertainment, and gaming industries. He is committed to pushing boundaries, challenging the status quo, and driving innovation in everything he does. With his unique expertise and passion for success, Wags is a leader and a force to be reckoned with.</p>`,

            },

        ];

        this.content = this.contentDefault + this.phrasesToDecode[0];

        const messageSubs = this.messageService.getMessage().subscribe((msj: MessageServiceModel) => {
            if (msj.type === 'textScrambler') {
                this.content = this.contentDefault + msj.payload;
            }
        });
        this.subscriptions.push(messageSubs);

        const scramblerSub = this.animationsService.textScrambler(this.content, 0, this.phrasesToDecode);
        if (scramblerSub) this.subscriptions.push(scramblerSub);

        this.headerVideoBlock = {
            fluid: true,
            aspectRatio: '16:9',
            autoplay: true,
            loop: true,
            controls: false,
            muted: true,
            preload: 'metadata',
            poster: 'assets/videos/poster-video.jpg',
            sources: [
                {
                    src: 'https://scalio-assets.scaliolabs.com/assets/video/scalio-home-hero3-d5d2718c42.mp4',
                    type: 'video/mp4',
                },
            ],
        } as VideoOptions;
        this.joinSection = [
            {
                image: 'https://via.placeholder.com/600x400',
                alt: 'electric pickup',
            },
            {
                image: 'https://via.placeholder.com/600x400',
                alt: 'electric pickup 2',
            },
            {
                image: 'https://via.placeholder.com/600x400',
                alt: 'electric pickup 3',
            },
            {
                image: 'https://via.placeholder.com/600x400',
                alt: 'electric pickup',
            },
            {
                image: 'https://via.placeholder.com/600x400',
                alt: 'electric pickup 2',
            },
            {
                image: 'https://via.placeholder.com/600x400',
                alt: 'electric pickup 3',
            },
        ];
        (this.cardsGlobalTech = [
            {
                parallaxContent: '600+',
                title: '600+ PROJECTS',
                subTitle: 'In 30+ Industries',
                content:
                    "Over 10 years, we've completed 600+ projects across 30+ industries. 9 of our clients have been acquired. Intuitive solutions made for the most complex challenges.",
            },
            {
                parallaxContent: '100+',
                title: '100+ MILLON',
                subTitle: 'Delighted Users',
                content:
                    'Our experts have crafted products that have engaged over 100 million users across dozens of verticals. From finanical tech, to healthcare, to gaming - we know how to deliver world-class experiences.',
            },
            {
                parallaxContent: '$1 Bil',
                title: '$1 BILLION+',
                subTitle: 'In Revenue',
                content:
                    'With hundreds of products shipped, our experts have delivered over $1 billion in revenue on products over the past 10 years.',
            },
            {
                parallaxContent: '75+',
                title: '75+ SPECIALISTS',
                subTitle: 'Globally',
                content:
                    'A global workforce celebrated in diversity of skill & background. 75 unique technical specialties, 43 countries, and one very special mission.',
            },
        ]),
            (this.innovativeProducts = [
                {
                    icon: 'lni lni-code-alt',
                    title: 'Mobile Engineering',
                },
                {
                    icon: 'lni lni-pencil-alt',
                    title: 'Design & User Experience',
                },
                {
                    icon: 'lni lni-rocket',
                    title: 'Strategy & Growth',
                },
                {
                    icon: 'lni lni-code',
                    title: 'Web Engineering',
                },
                {
                    icon: 'lni lni-cloud',
                    title: 'DevOps & Hosting',
                },
                {
                    icon: 'lni lni-cog',
                    title: 'Support & Maintenance',
                },
            ]),
            (this.cardsWithLinkCarousel = [
                {
                    desc: 'I have nothing but great things to say about working with Scalio. They are one of the good ones...',
                    iconSet: false,
                    iconName: '/assets/icons/icon-person-doc.svg',
                    isCard: false,
                    hasLink: true,
                    linkUrl: '/',
                },
                {
                    desc: 'I have nothing but great things to say about working with Scalio. They are one of the good ones...',
                    iconSet: false,
                    iconName: '/assets/icons/icon-person-doc.svg',
                    isCard: false,
                    hasLink: true,
                    linkUrl: '/',
                },
                {
                    desc: 'I have nothing but great things to say about working with Scalio. They are one of the good ones...',
                    iconSet: false,
                    iconName: '/assets/icons/icon-person-doc.svg',
                    isCard: false,
                    hasLink: true,
                    linkUrl: '/',
                },
            ] as CardModel[]);
        this.loadingStateService?.stop();
    }
}
